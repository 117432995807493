import { NgFor } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TabNames, TopMenuItems } from '@const';
import { CALENDARS_MENU, SCANNERS_MENU, STRATEGIES_MENU, TOOLS_MENU, TRADING_MENU } from '@mod/header/header-v2.models';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, MatIconModule, MatIconModule, MatMenuModule, MatButtonModule, NgFor, MatSidenavModule],
})
export class HeaderV2Component {
  strategiesMenu = STRATEGIES_MENU;
  tradingMenu = TRADING_MENU;
  scannersMenu = SCANNERS_MENU;
  calendarsMenu = CALENDARS_MENU;
  toolsMenu = TOOLS_MENU;

  public demoPage = false;

  activeTab = '';

  @Output() onOpenLeftMenu = new EventEmitter<boolean>();
  @Output() redirectToStrategyTab = new EventEmitter();

  constructor(
    private navigationService: NavigationService,
    private observableService: ObservableService,
  ) {}

  public openItem(item: string): void {
    switch (item) {
      case TopMenuItems.MasterCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.MasterCalendar);
        break;

      case TopMenuItems.DividendsCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.DividendsCalendar);
        break;

      case TopMenuItems.EarningsCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.EarningsCalendar);
        break;

      case TopMenuItems.EconomicCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.EconomicCalendar);
        break;

      case TopMenuItems.HolidaysCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.HolidaysCalendar);
        break;

      case TopMenuItems.MMTrades:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.MMTrades);
        break;

      case TopMenuItems.AssetCorrelation:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.AssetCorrelation);
        break;

      case TopMenuItems.TradingLog:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.TradingLog);
        break;

      case TopMenuItems.TradingPanel:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.TradingPanel);
        break;

      case TopMenuItems.StockScreener:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.StockScreeners);
        break;

      case TopMenuItems.DividendsAnalysis:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.DividendsStrategy);
        break;

      case TopMenuItems.Heatmap:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.Heatmap);
        break;

      case TopMenuItems.ShortingStocksScanner:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.ShortingScanner);
        break;

      default:
        break;
    }
  }

  private resetStateOnPageSwitch(): void {
    // Cancel any pending order when switching between tabs
    this.observableService.tradingPanelOrderInput.next(null);
  }

  openLeftMenu(): void {
    this.onOpenLeftMenu.emit();
  }

  redirectToMenuItem(item): void {
    this.redirectToStrategyTab.emit(item);
  }
}
