<div class="secondary-navbar">
  <mat-sidenav-container class="sidenav-container">
    <mat-toolbar class="toolbar-top" id="header-toolbar">
      <div #menuWrapper class="dropped-links-wrapper" [class.hidden]="!showMenu">
        <!--li *ngFor="let item of mainMenu; let i = index" class="decorated-link top-menu-item">
          <a mat-button (click)="openItem(item)" [appFeatureAvailability]="item.feature">
            {{ item.title }}
          </a>
        </li-->
        <app-header-v2 (onOpenLeftMenu)="openLeftMenu()" (redirectToStrategyTab)="redirectToStrategyTab($event)"></app-header-v2>

        <div id="drop-down-items" class="drop-down-items" *ngIf="hiddenMenuItems?.length > 0">
          <li #li class="drop-down-link" (click)="li.classList.add('active')">
            <a mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="hiddenMenuItems">
              MORE
              <mat-icon iconPositionEnd class="extra-low-icon drop-down-list-icon"
                [svgIcon]="'arrow-nav-icon'"></mat-icon>
            </a>
          </li>

          <mat-menu #menu="matMenu" class="top-menu-content" id="hidden-prio-nav"
            (closed)="li.classList.remove('active')">
            <ng-template matMenuContent>
              <button *ngFor="let item of hiddenMenuItems" type="button" aria-label="Toggle sidenav" mat-button
                [appFeatureAvailability]="item.feature" (click)="openItem(item)">
                <span>{{ item.title }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </div>

      <div id="toolbar-right-section" class="toolbar-right-section">
        <ul>
          <li #li (click)="li.classList.add('active')" class="drop-down-link">
            <a mat-button class="help-link" [matMenuTriggerFor]="help">
              <mat-icon class="middle-icon help-icon" [svgIcon]="'help'"></mat-icon>
              Help
              <mat-icon iconPositionEnd class="extra-low-icon drop-down-list-icon"
                [svgIcon]="'arrow-nav-icon'"></mat-icon>
            </a>

            <mat-menu #help="matMenu" class="top-menu-content" (closed)="li.classList.remove('active')">
              <ng-template matMenuContent>
                <button mat-button class="mat-button-link">
                  <a target="_blank" href="https://members.rockwelltrading.com/courses/2914152/content">PXO
                    Tutorials</a>
                </button>

                <ng-container *appHideForUnavailableFeature="features.PxoCommunity">
                  <button mat-button *ngIf="!isTrialUser" class="mat-button-link">
                    <a target="_blank" href="https://members.rockwelltrading.com/groups/2871771/feed">PXO Community</a>
                  </button>
                </ng-container>

                <button mat-button class="mat-button-link">
                  <a target="_blank"
                    href="https://app.loopedin.io/powerx-optimizer/kb/table-of-contents/table-of-contents">
                    PXO User Guide
                  </a>
                </button>
                <button mat-button class="mat-button-link">
                  <a target="_blank" href="https://www.rockwelltrading.com/contact-us/">Contact Support</a>
                </button>
                <button mat-button class="mat-button-link">
                  <a target="_blank" href="https://www.rockwelltrading.com/pxo-releases">Release Notes</a>
                </button>
                <button mat-button class="mat-button-link">
                  <a (click)="$event.preventDefault(); navigateToLicenseAgreementPage()">License Agreement</a>
                </button>
                <button mat-button class="mat-button-link">
                  <a (click)="$event.preventDefault(); navigateToDisclaimerPage()">Disclaimer</a>
                </button>
              </ng-template>
            </mat-menu>
          </li>

          <li *appHideForUnavailableFeature="features.TradingHub">
            <mat-icon [svgIcon]="'magic-ball-top-menu-icon'" class="magic-ball-icon"
              (click)="openTradingHubModal()"></mat-icon>
          </li>

          <li *ngIf="joinCommunityBtnVisible && !isTrialUser">
            <a (click)="hideJoinButtons(userSettings.JoinCommunityBtnVisible)" mat-button target="_blank"
              class="tutorials-link"
              href="https://members.rockwelltrading.com/share/F1kkInI5JyMKDWVa?utm_source=manual">
              <mat-icon class="middle-icon community-icon" [svgIcon]="'community'"></mat-icon>
              Community
            </a>
          </li>
          <li *ngIf="watchPxoTutorialsBtnVisible">
            <a (click)="hideJoinButtons(userSettings.WatchPxoTutorialsBtnVisible)" mat-button target="_blank"
              class="tutorials-link"
              href="https://members.rockwelltrading.com/share/xNjTIyAHkCScz6ot?utm_source=manual">
              <mat-icon class="middle-icon tutorials-icon" [svgIcon]="'tutorials'"></mat-icon>
              Tutorials
            </a>
          </li>
        </ul>

        <app-trading-hub-top-menu-button
          [appFeatureAvailability]="features.TradingHub"></app-trading-hub-top-menu-button>
      </div>
    </mat-toolbar>
  </mat-sidenav-container>
</div>