<div class="header-v2-navbar">
  <mat-toolbar class="toolbar-top" id="header-toolbar">
    <mat-icon [svgIcon]="'menu-icon'" class="header-menu-icon" (click)="openLeftMenu()"></mat-icon>
    <div #menuWrapper class="dropped-links-wrapper">
      <li #strategiesMenuTrigger class="drop-down-link" (click)="strategiesMenuTrigger.classList.add('active')">
        <a mat-button [matMenuTriggerFor]="strategies" [disableRipple]="true" [matMenuTriggerData]="strategies">
          STRATEGIES </a>
      </li>
      <mat-menu #strategies="matMenu" class="header-v2-navbar-menu"
        (closed)="strategiesMenuTrigger.classList.remove('active')">
        <ng-template matMenuContent>
          <button *ngFor="let item of strategiesMenu" type="button" [disableRipple]="true" mat-button (click)="redirectToMenuItem(item)">
            <span class="menu-item">
              <mat-icon class="strategy-icon" [svgIcon]="item.icon"></mat-icon>
              <span class="menu-item-text strategy-item-text">
                {{ item.name }}
              </span>
              @if(demoPage) {
              <span class="lock-icon">
                <mat-icon svgIcon="lock-icon"></mat-icon>
              </span>
            }
            </span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
    <div #menuWrapper class="dropped-links-wrapper">
      <li #tradingMenuTrigger class="drop-down-link" (click)="tradingMenuTrigger.classList.add('active')">
        <a mat-button [matMenuTriggerFor]="trading" [disableRipple]="true" [matMenuTriggerData]="trading"> TRADING </a>
      </li>
      <mat-menu #trading="matMenu" class="header-v2-navbar-menu"
        (closed)="tradingMenuTrigger.classList.remove('active')">
        <ng-template matMenuContent>
          <button *ngFor="let item of tradingMenu" type="button" [disableRipple]="true" mat-button
            (click)="openItem(item.tabName)">
            <span class="menu-item">
              <mat-icon class="menu-icon" [svgIcon]="item.icon"> </mat-icon>
              <span class="menu-item-text">
                {{ item.name }}
              </span>
            </span>
          </button>
        </ng-template>
        <div class="trading-hub-container">
          <span class="trading-hub-text">MY TRADING HUB</span>
          <button class="trading-hub-btn">Trading Plan</button>
          <button class="trading-hub-btn">Trading Checklist</button>
        </div>
      </mat-menu>
    </div>
    <div #menuWrapper class="dropped-links-wrapper">
      <li #scannersMenuTrigger class="drop-down-link" (click)="scannersMenuTrigger.classList.add('active')">
        <a mat-button [matMenuTriggerFor]="scanners" [disableRipple]="true" [matMenuTriggerData]="scanners"> SCANNERS
        </a>
      </li>
      <mat-menu #scanners="matMenu" class="header-v2-navbar-menu"
        (closed)="scannersMenuTrigger.classList.remove('active')">
        <ng-template matMenuContent>
          <button *ngFor="let item of scannersMenu" type="button" [disableRipple]="true" mat-button
            (click)="openItem(item.tabName)">
            <span class="menu-item">
              <mat-icon class="menu-icon" [svgIcon]="item.icon"> </mat-icon>
              <span class="menu-item-text">
                {{ item.name }}
              </span>
            </span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
    <div #menuWrapper class="dropped-links-wrapper">
      <li #calendarMenuTrigger class="drop-down-link" (click)="calendarMenuTrigger.classList.add('active')">
        <a mat-button [matMenuTriggerFor]="calendars" [disableRipple]="true" [matMenuTriggerData]="calendars"> CALENDARS
        </a>
      </li>
      <mat-menu #calendars="matMenu" class="header-v2-navbar-menu"
        (closed)="calendarMenuTrigger.classList.remove('active')">
        <ng-template matMenuContent>
          <button *ngFor="let item of calendarsMenu" type="button" [disableRipple]="true" mat-button
            (click)="openItem(item.tabName)">
            <span class="menu-item">
              <mat-icon class="menu-icon" [svgIcon]="item.icon"> </mat-icon>
              <span class="menu-item-text">
                {{ item.name }}
              </span>
            </span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
    <div #menuWrapper class="dropped-links-wrapper">
      <li #toolsMenuTrigger class="drop-down-link" (click)="toolsMenuTrigger.classList.add('active')">
        <a mat-button [matMenuTriggerFor]="tools" [disableRipple]="true" [matMenuTriggerData]="tools"> TOOLS </a>
      </li>
      <mat-menu #tools="matMenu" class="header-v2-navbar-menu" (closed)="toolsMenuTrigger.classList.remove('active')">
        <ng-template matMenuContent>
          <button [disableRipple]="true" *ngFor="let item of toolsMenu" type="button" aria-label="Toggle sidenav"
            mat-button (click)="openItem(item.tabName)">
            <span class="menu-item">
              <mat-icon class="menu-icon" [svgIcon]="item.icon"> </mat-icon>
              <span class="menu-item-text">
                {{ item.name }}
              </span>
            </span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </mat-toolbar>
</div>