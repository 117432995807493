import { Features, TabNames, TopMenuItems } from '@const';

export enum strategiesNames {
  POWER_X = 'PowerX',
  WHEEL = 'The Wheel',
  BCS = 'BCS',
  WTF = 'WTF',
  SOL = 'SOL',
  SYSTEM_ONE = 'System 1',
  DIVIDENDS = 'Dividends',
}

export const STRATEGIES_MENU = [
  {
    name: strategiesNames.POWER_X,
    icon: 'power-x-icon',
    tabName: TabNames.PowerX,
    featuresAvailability: Features.PowerX,
  },
  {
    name: strategiesNames.WHEEL,
    icon: 'wheel-icon',
    tabName: TabNames.Wheel,
    featuresAvailability: Features.Wheel,
  },
  {
    name: strategiesNames.BCS,
    icon: 'bcs-icon',
    tabName: TabNames.BullCallSpread,
    featuresAvailability: Features.BullCallSpread,
  },
  {
    name: strategiesNames.WTF,
    icon: 'wtf-icon',
    tabName: TabNames.Wtf,
    featuresAvailability: Features.Wtf,
  },
  {
    name: strategiesNames.SOL,
    icon: 'sol-icon',
    tabName: TabNames.ShortSellingStocks,
    featuresAvailability: Features.ShortSellingStocks,
  },
  {
    name: strategiesNames.SYSTEM_ONE,
    icon: 'system-one-icon',
    tabName: TabNames.ShortingScanner,
    featuresAvailability: Features.ShortingStocksScanner,
  },
  {
    name: strategiesNames.DIVIDENDS,
    icon: 'dividends-icon',
    tabName: TabNames.DividendsStrategy,
    featuresAvailability: Features.DividendsStrategy,
  },
];

export const TRADING_MENU = [
  {
    name: 'Trading Log',
    icon: 'table_chart_icon',
    tabName: TopMenuItems.TradingLog,
  },
  {
    name: 'Trading Panel',
    icon: 'trading_panel_icon',
    tabName: TopMenuItems.TradingPanel,
  },
];

export const SCANNERS_MENU = [
  {
    name: 'Stock Screeners',
    icon: 'hot_list_icon',
    tabName: TopMenuItems.StockScreener,
  },
  {
    name: 'Earning Analysis',
    icon: 'correlation',
    tabName: TopMenuItems.EarningsAnalysis,
  },
];

export const CALENDARS_MENU = [
  {
    name: 'Master',
    icon: 'star-icon',
    tabName: TopMenuItems.MasterCalendar,
  },
  {
    name: 'Economic',
    icon: 'economic-calendar',
    tabName: TopMenuItems.EconomicCalendar,
  },
  {
    name: 'Earnings',
    icon: 'earnings-calendar',
    tabName: TopMenuItems.EarningsCalendar,
  },
  {
    name: 'Dividents',
    icon: 'dividends-calendar',
    tabName: TopMenuItems.DividendsCalendar,
  },
  {
    name: 'Holidays',
    icon: 'wb_sunny_icon',
    tabName: TopMenuItems.HolidaysCalendar,
  },
];

export const TOOLS_MENU = [
  {
    name: 'Assets Correlation',
    icon: 'correlation',
    tabName: TopMenuItems.AssetCorrelation,
  },
  {
    name: 'M&M Trades',
    icon: 'pie_chart_icon',
    tabName: TopMenuItems.MMTrades,
  },
  {
    name: 'Heatmap',
    icon: 'heatmap',
    tabName: TopMenuItems.Heatmap,
  },
];
